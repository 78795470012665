<template>
    <div style="margin-top: 40px;">
        <table
            class="vs-table vs-table--tbody-table"
            cellspacing="0"
            cellpadding="0"
        >
            <thead class="description-products">
                <tr>
                    <th colspan="1" rowspan="1" style="width: 13% !important; border-radius: 8px 0% 0% 8px;">
                        <div class="vs-table-text pl-6">Selecciona una opción</div>
                    </th>
                    <th colspan="1" rowspan="1" style="width: 35%; !important">
                        <div class="vs-table-text pl-6">Paquetes tradicionales</div>
                    </th>
                    <th colspan="1" rowspan="1" style="border-radius: 0% 8px 8px 0%;">
                        <div class="vs-table-text">
                            Tus Servicios tradicionales de forma clara y
                            sencilla
                        </div>
                    </th>
                </tr>
            </thead>
            <div style="height: 20px;"></div>
          <tr v-for="category in projectCategories" :key="category.id">
            <td colspan="3" style="width: 100%;">
              <div v-if="category.id == 0" icon-arrow="expand_more" style="border-radius: 8px;">
                    <div style="padding: 15px; font-size: 18px;" slot="header" class="category-style">{{ category.name }}</div>
                      <tr class="tr-values vs-table--tr tr-table-state-null" v-for="product in projectTypes" :key="product.id" v-if="product.category_ids.includes(category.id)">
                            <div style="height: 20px;"></div>
                            <td class="mr-3 ml-1" style="display: flex; align-items: center; height: 100%">
                                <vs-row>
                                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                                        <vs-checkbox
                                            :id="'product' + product.id + category.id"
                                            v-model="traditionalProductsSelected"
                                            :vs-value="product.id"
                                        ></vs-checkbox>
                                    </vs-col>
                                </vs-row>
                            </td>
                            <td class="td vs-table--td product-gw" style="width: 425px;">
                                <span class="ml-8">
                                    <span class="ml-8">{{ product.name }}</span>
                                </span>
                            </td>
                            <td class="td vs-table--td product-gw">
                                <span>
                                    <span>{{ product.category }}</span>
                                </span>
                            </td>
                            <td class="td vs-table--td produc-description-gw">
                                <span>
                                    <span class="flex items-center px-2 py-1 rounded">
                                        {{ product.description }}
                                    </span>
                                </span>
                            </td>
                      </tr>
                    </div>
              <vs-collapse>
                <vs-collapse-item v-if="category.id > 0" icon-arrow="expand_more" style="border-radius: 8px; background: #FBFAFC">
                    <div slot="header" class="category-style" style="font-size: 18px; padding: 18px;">{{ category.name }}</div>
                      <tr class="tr-values vs-table--tr tr-table-state-null" v-for="product in projectTypes" :key="product.id" v-if="product.category_ids.includes(category.id)">
                        <div style="height: 20px;"></div>
                        <td class="mr-3 ml-1" style="display: flex; align-items: center; height: 100%;">
                                <vs-row>
                                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                                        <vs-checkbox
                                            :id="'product' + product.id + category.id"
                                            v-model="traditionalProductsSelected"
                                            :vs-value="product.id"
                                        ></vs-checkbox>
                                    </vs-col>
                                </vs-row>
                            </td>
                            <td class="td vs-table--td product-gw" style="width: 380px;">
                                <span class="ml-8">
                                    <span class="product-name"><p>{{ product.name }}</p></span>
                                </span>
                            </td>
                            <td class="td vs-table--td product-gw">
                                <span>
                                    <span>{{ product.category }}</span>
                                </span>
                            </td>
                            <td class="td vs-table--td produc-description-gw">
                                <span>
                                    <span class="flex items-center px-2 py-1 rounded">
                                        {{ product.description }}
                                    </span>
                                </span>
                            </td>
                      </tr>
                </vs-collapse-item>
              </vs-collapse>
            </td>
          </tr>
        </table>
    </div>
</template>
<script>
import api from "../../mixins/api.js";
import { mapGetters } from "vuex";
export default {
    mixins: [api],
    name: "Packages",
    props: {
        contract_id: null,
        productsProject: null
    },
    computed: {
        ...mapGetters(["userInfo"]),
        contract_idChild: {
            get() {
                return this.contract_id;
            },
            set(value) {
                this.$emit("update:contract_id", value);
            }
        },
        productsProjectChild: {
            get() {
                return this.productsProject;
            },
            set(value) {
                this.$emit("update:productsProject", value);
            }
        }
    },
    data() {
        return {
            clientId: null,
            activeItems: [0],
            contracts: [],
            contracts_products: [],
            traditionalProductsSelected: [],
            traditionalProducts: [],
            individualProductsSelected: [],
            individualProducts: [],
            projectCategories: [
                {
                    id: 0,
                    name: "Servicios principales"
                },
                {
                    id: 1,
                    name: "Servicios de verificación de información"
                },
                {
                    id: 2,
                    name: "Servicios especializados"
                },
            ],
            projectTypes: [
                {
                    id: 2,
                    name: "Estudio de seguridad",
                    category_ids: [0],
                    description:
                        "Este paquete es un servicio ideal y complementario que se compone de: verificación de antecedentes, 2 verificaciones académicas, 2 referencias laborales y 2 referencias personales."
                },
                {
                    id: 6,
                    name: "Visita domiciliaria básica",
                    category_ids: [0],
                    description:
                        "Realizamos visita domiciliaria al candidato (modalidad virtual o presencial) por parte de un profesional en psicología y/o trabajo social.  Se presenta informe con análisis sociofamiliar y registro fotográfico."
                },
                {
                    id: 4,
                    name: "Prueba de poligrafía",
                    category_ids: [0],
                    description:
                        "Principal prueba de verificación y evaluación de riesgos laborales. Evalúa los riesgos, detectando amenazas para resguardar la integridad empresarial. Asegura la precisión de la información proporcionada."
                },
                {
                    id: 5,
                    name: "Antecedentes judiciales",
                    category_ids: [0],
                    description:
                        "Consultamos más de 40 bases de datos públicas y privadas, tanto nacionales (como Rama Judicial, Policía, Procuraduría, Simit) como internacionales (por ejemplo, OFAC, Interpol, Clinton, FBI, Marshal, entre otras)."
                },
                {
                    id: 13,
                    name: "Visita domiciliaria + Estudio de seguridad",
                    category_ids: [0],
                    description:
                        "Realizamos verificación de antecedentes, académicas y referencias laborales y personales, junto con un registro fotográfico detallado y evaluación socioeconómica. Toma decisiones informadas con nuestro análisis integral."
                },
                {
                    id: 8,
                    name: "Verificación académica",
                    category_ids: [1],
                    description:
                        "Verificamos los dos estudios más relevantes para nuestros aliados a través de verificación con las instituciones, ECAES o ICFES, grafología y tarjetas profesionales."
                },
                {
                    id: 10,
                    name: "Referencias laborales",
                    category_ids: [1],
                    description:
                        "Se verifican 2 referencias con los jefes inmediatos, para validar temas de desempeño, como cuánto tiempo laboró en la organización, cuál fue su desempeño de 1 a 5 y si lo volverían a contratar o no."
                },
                {
                    id: 3,
                    name: "Verificación en centrales de riesgo",
                    category_ids: [1],
                    description:
                        "Puedes obtener este servició de verificación en el que se evalúa el comportamiento financiero y las deudas adquiridas por el candidato, para mitigar riesgos de cargos con acceso a dinero."
                },
                {
                    id: 11,
                    name: "Verificación académica y referencias laborales",
                    category_ids: [1],
                    description:
                        "Verificamos los dos estudios más relevantes para nuestros aliados a través de verificación con las instituciones y se verifican 2 referencias con los jefes inmediatos, para validar temas de desempeño."
                },
                {
                    id: 19,
                    name: "Verificación de antecedentes y riesgo financiero",
                    category_ids: [1],
                    description:
                        "Consultamos más de 40 bases de datos públicas y privadas nacionales e internacionales y también se evalúa el comportamiento financiero y las deudas adquiridas por el candidato."
                },
                {
                    id: 7,
                    name: "Verificación académica y antecedentes judiciales",
                    category_ids: [1],
                    description:
                        "Consultamos más de 40 bases de datos públicas y privadas y verificamos los dos estudios más relevantes para nuestros aliados a través de verificación con las instituciones, ECAES o ICFES, grafología y tarjetas profesionales."
                },
                {
                    id: 20,
                    name: "Verificación de antecedentes, referencias laborales y personales",
                    category_ids: [1],
                    description: "Consultamos más de 40 bases de datos públicas y privadas, se verifican 2 referencias con los jefes inmediatos, para validar temas de desempeño y validamos el grado de consanguinidad que tenga con el aspirante."
                },
                {
                    id: 9,
                    name: "Verificación académica, referencias laborales y personales",
                    category_ids: [1],
                    description:
                        "Verificamos estudios clave con instituciones, referencias laborales con jefes inmediatos y relaciones familiares del aspirante para garantizar la integridad de nuestros aliados."
                },
                {
                    id: 12,
                    name: "Visita OEA",
                    category_ids: [2],
                    description:
                        "Consigue la certificación OEA fácilmente con nuestro servicio especializado. Este paquete incluye Visita domiciliaria, verificación de antecedentes y verificación crediticia."
                },
                {
                    id: 15,
                    name: "Visita teletrabajo completo",
                    category_ids: [2],
                    description:
                        "Optimiza el entorno de trabajo remoto de tus colaboradores. Diagnostica y evalúa las condiciones para el teletrabajo, asegurando espacios eficientes y cómodos para un rendimiento óptimo del equipo."
                },
                // {
                //     id: 1,
                //     name: "Prueba psicotécnica",
                //     category_ids: [2],
                //     description:
                //         "Evalúa competencias clave de manera eficaz y toma decisiones informadas para construir equipos sólidos y exitosos."
                // }
            ]
        };
    },
    methods: {
        getContractsActive() {
            var path = "/api/v1/clients/" + this.clientId + "/contracts_active";
            var url = this.url() + path;
            this.requestGetWithoutContent(url, {}).then(
                response => {
                    console.log("response " + JSON.stringify(response));
                    if (response.status == 200) {
                        this.contracts = response.data[0];
                        this.contract_idChild = this.contracts.id;
                        this.contracts_products = this.contracts.contracts_products;
                        this.createIndividualProducts();
                        this.createTraditionalProducts();
                    } else {
                        console.log("No se pudo obtener los contratos ");
                    }
                },
                err => {
                    console.log("err users -> " + JSON.stringify(err));
                }
            );
        },
        createIndividualProducts() {
            this.contracts_products.forEach(contractProduct => {
                var assignment_types = contractProduct.product.assignment_types;
                if (assignment_types.length == 1) {
                    var project_type_id = contractProduct.product.project_type;
                    if (project_type_id != null) {
                        project_type_id = project_type_id.id;
                    }
                    let assignment_types_id = 0;
                    assignment_types.forEach(assignment_type => {
                        assignment_types_id = assignment_type.id;
                    });
                    var objectProduct = {
                        id: contractProduct.product.id,
                        name: contractProduct.product.name,
                        project_type_id: project_type_id,
                        assignment_types: assignment_types_id,
                        description: contractProduct.product.description,
                        price: contractProduct.price
                    };
                    this.individualProducts.push(objectProduct);
                }
            });
        },
        createTraditionalProducts() {
            this.contracts_products.forEach(contractProduct => {
                var assignment_types = contractProduct.product.assignment_types;
                if (assignment_types.length > 1) {
                    var project_type_id = contractProduct.product.project_type;
                    if (project_type_id != null) {
                        project_type_id = project_type_id.id;
                    }
                    let assignment_types_id = 0;
                    assignment_types.forEach(assignment_type => {
                        assignment_types_id = assignment_type.id;
                    });
                    var objectProduct = {
                        id: contractProduct.product.id,
                        name: contractProduct.product.name,
                        project_type_id: project_type_id,
                        assignment_types: assignment_types_id,
                        description: contractProduct.product.description,
                        price: contractProduct.price
                    };
                    this.traditionalProducts.push(objectProduct);
                }
            });
        },
        updateProducts() {
            if (this.traditionalProductsSelected.length > 0) {
                this.productsProjectChild = this.traditionalProductsSelected;
            }
            if (this.individualProductsSelected.length > 0) {
                this.productsProjectChild = this.individualProductsSelected;
            }
            if (
                this.traditionalProductsSelected.length == 0 &&
                this.individualProductsSelected.length == 0
            ) {
                this.productsProjectChild = null;
            }
        }
    },
    watch: {
        traditionalProductsSelected() {
            if (this.traditionalProductsSelected.length == 1) {
                this.individualProductsSelected = [];
            }
            if (this.traditionalProductsSelected.length > 1) {
                this.traditionalProductsSelected.shift();
            }
            this.updateProducts();
        },
        individualProductsSelected() {
            if (this.individualProductsSelected.length > 0) {
                this.traditionalProductsSelected = null;
            }
            this.updateProducts();
        }
    },
    created() {
        this.clientId = this.userInfo.user.client_id;
        // this.getContractsActive()
    }
};
</script>
<style>
.product-gw {
    font-weight: 600;
    transform: translateY(-4.9px);
}
.produc-description-gw {
    font-weight: 400;
    color: #9a9a9a;
    font-size: 14px;
    transform: translateY(-4.9px);
}
.description-products th {
    background-color: #5f0090;
    color: #ffffff;
    font-size: 16px;
    padding: 8px;
}
.category-style{
  color: #5F008F;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 510;
  line-height: normal;
  padding: 5px;
}
.vs-collapse-item .material-icons {
  font-size: 20px;
  color: #5F008F;
}
.product-name {
  position: absolute;
}
</style>
